import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  learnerData: {
    subcategory: "",
    genre: "",
    event: "",
    location: "",
    time: "",
    frequency: "",
    mode: "",
    goal: "",
    exGender: "",
    expert: "",
    experts: ""
  },
  llogin: {
    isLoggedIn: false,
    loginAuth: {},
    profile: {}
  },
  isLocate: false
};

const learnerSlice = createSlice({
  name: "learner",
  initialState,
  reducers: {
    setLearnerData: (state, action) => {
      state.learnerData = action.payload;
    },
    setLocate: (state, action) => {
      state.isLocate = action.payload;
    },
    setLearnerLogin: (state, action) => {
      state.llogin = action.payload;
    }
    }
  },
);

export const { setLearnerData, setLearnerLogin, setLocate } = learnerSlice.actions;

export default learnerSlice.reducer;
